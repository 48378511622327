import React from "react";

class Header extends React.Component {
  render() {
    return (
      <header id="header" className="page-header ">
        <div className="page-header__wrapper">
          <a
            href="#"
            id="touch-menu"
            className="page-header__nav-button"
            data-icon="list"
          />
          <a
            href="/index.html"
            title="Home"
            rel="home"
            className="page-header__site-logo"
          >
            <img
              src="/img/kenvue-logo-black-rgb.svg"
              className="page-header__site-logo-image"
              alt="Home"
            />
          </a>
        </div>
        <nav className="main-menu">
          <ul className="main-menu__list" role="menubar">
            <li className="main-menu__item" role="none">
              <a href="/index.html" className="main-menu__link" role="menuitem">
                Home
              </a>
            </li>
            <li className="main-menu__item" role="none">
              <a
                href="/marken"
                className="main-menu__link"
                role="menuitem"
              >
                Marken
              </a>
            </li>
            <li className="main-menu__item" role="none">
              <a
                href="/karriere"
                className="main-menu__link"
                role="menuitem"
              >
                Karriere
              </a>
            </li>
            <li className="main-menu__item" role="none">
              <a
                href="/kontakt"
                className="main-menu__link"
                role="menuitem"
              >
                Kontakt &amp; Service
              </a>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}

export default Header;
