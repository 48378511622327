import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <a href="#content" className="sr-only focusable">
          Direkt zum Seiteninhalt
        </a>
        <div id="top" className="page-wrapper">
          <Header></Header>
          <main id="content" className="page-content">
            <ul className="breadcrumb">
              <li className="breadcrumb__list-item">
                <a href="/" className="breadcrumb__link">
                  Home
                </a>
              </li>
              <li className="breadcrumb__list-item">
                <span className="breadcrumb__text">&nbsp;Rechtlicher Hinweis</span>
              </li>
            </ul>
            <h1 className="page-headline">Rechtlicher Hinweis</h1>
            <div className="margin-module">
              <div className="margin-module__main-column">
                <div className="text-module">
                  <h3 className="h3">
                    Durch den Zugriff und das Durchblättern der Website stimmen
                    Sie ohne Einschränkung oder Vorbehalt den nachfolgenden
                    Bedingungen zu.
                  </h3>
                  <p>
                    Der Inhalt dieser Internetseite ist rechtlich in allen
                    seinen Teilen geschützt. Jede Verwertung außerhalb der engen
                    Grenzen des Urheberrechts ist ohne Zustimmung von Johnson
                    &amp; Johnson GmbH unzulässig. Sie können die Informationen
                    ausschließlich zu Ihrer persönlichen Verwendung zu
                    nicht-gewerblichen Zwecken herunterladen. Der Inhalt darf
                    anderweitig in keiner Weise kopiert oder genutzt werden.
                  </p>
                  <p>
                    Die Betreiber dieser Website werden in angemessener Weise
                    Anstrengungen unternehmen, damit diese Website aktuelle und
                    genaue Informationen enthält. Sie geben aber keine
                    Zusicherungen, Gewährleistungen oder Zusagen hinsichtlich
                    der Richtigkeit, Gültigkeit oder Vollständigkeit der
                    bereitgestellten Informationen. Johnson &amp; Johnson GmbH
                    haftet nicht für Schäden, die sich aus dem Zugriff oder
                    fehlenden Zugriff auf diese Website oder daraus ergeben,
                    dass Sie sich auf Informationen aus dieser Website verlassen
                    haben. Diese Website richtet sich an Einwohner von
                    Österreich.
                  </p>
                  <p>
                    Diese Website kann auch Links oder Verweise auf andere
                    Websites enthalten, aber die Betreiber dieser Website
                    übernehmen keine Verantwortung für den Inhalt solcher
                    Websites und keine Haftung für Schäden oder Verletzungen,
                    die aus der Nutzung – gleich welcher Art – solcher Inhalte
                    entstehen. Links zu anderen Websites werden den Nutzern
                    dieser Website lediglich als Annehmlichkeit zur Verfügung
                    gestellt.
                  </p>
                  <p>
                    Die Marken, Servicemarken, Handelsnamen, Handelsaufmachungen
                    und Produkte dieser Website sind in Österreich sowie
                    international geschützt. Sie dürfen in keiner Weise ohne die
                    vorherige schriftliche Zustimmung der Betreiber dieser
                    Website genutzt werden, außer zur Identifizierung der
                    Produkte oder Serviceleistungen des Unternehmens.
                  </p>
                  <p>
                    Alle personenbezogenen Daten, die in elektronischer
                    Kommunikation über diese Website übermittelt werden,
                    unterliegen der Datenschutzerklärung dieser Website. Johnson
                    &amp; Johnson GmbH ist berechtigt, alle in solcher
                    Kommunikation offengelegten Informationen, einschließlich
                    aller darin enthaltenen Ideen, Erfindungen, Konzepte,
                    Methoden oder enthaltenes Know-how für jeden Zweck zu
                    verwenden, beispielsweise für die Bekanntgabe an Dritte
                    und/oder die Entwicklung, Herstellung und/oder Vermarktung
                    von Produkten oder Dienstleistungen.
                  </p>
                  <p>
                    Der Absender jeglicher Kommunikation an diese Website oder
                    an Johnson &amp; Johnson GmbH ist für den Inhalt sowie die
                    darin enthaltenen Informationen, einschließlich deren
                    Richtigkeit und Genauigkeit, verantwortlich. Johnson &amp;
                    Johnson GmbH behält sich das Recht vor, den Inhalt dieser
                    Website jederzeit aus jedwedem Grund ohne Ankündigung zu
                    streichen, zu ändern oder zu ergänzen.
                  </p>
                </div>
              </div>
              <div className="margin-module__margin-column">
                <div className="image-module">
                  <img
                    src="/img/content/johnson-johnson-austria.jpg"
                    className="image-module__image"
                    alt="Kenvue Austria"
                    height="196"
                    width="300"
                  />
                </div>
                <div className="text-module">
                  <h3 className="h3">
                    Standort Österreich
                    <br />
                    Johnson&nbsp;&amp;&nbsp;Johnson GmbH
                  </h3>
                  <p>
                    Vorgartenstraße 206B
                    <br />
                    A-1020 Wien
                    <br />
                    Tel: <a>+43(0) 1 725 / 15 - 0</a>
                    <br />
                    Fax: <a>+43(0) 1 725 / 15 - 9415</a>
                  </p>
                </div>
                <div className="text-module">
                  <h3 className="h3">Kontakt</h3>
                  <p>
                    Sie haben eine Frage zum Unternehmen?{" "}
                    <a href="/kontakt">Kontaktieren</a> Sie uns!
                  </p>
                </div>
                <div className="text-module">
                  <h3 className="h3">Datenschutz</h3>
                  <p>
                    Informieren Sie sich über den Umgang mit{" "}
                    <a href="/datenschutz">Daten</a> bei
                    Johnson&nbsp;&amp;&nbsp;Johnson GmbH!
                  </p>
                </div>
              </div>
            </div>
          </main>
          <Footer></Footer>
        </div>
        <script src="/js/main.js" />
        
        <script src="https://www.google-analytics.com/analytics.js" async />
      </div>
    );
  }
}

export default Page;
